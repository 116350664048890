import React from "react"
import { Dialog } from "@mui/material"
import PropTypes from "prop-types"

function Modal({ open, handleClose, children }) {
  return (
    <Dialog
      className="Modal"
      fullWidth
      maxWidth="lg"
      style={{
        overflowX: "hidden",
      }}
      open={open}
      onClose={() => handleClose()}
    >
      {children}
    </Dialog>
  )
}

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default Modal
