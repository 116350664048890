import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Alert, Box, Button, List, ListItem, Typography } from "@mui/material"
import Modal from "../modal"
import { pxToRem } from "../../soe-theme/src/utils"
import InexBusinessLogo from "../../soe-theme/src/components/inex-business-logo"

// Made edits here and to the assets/locales/[language]/persisted-alert.json files to update this persisted alert when needed.
function PersistedAlert() {
  const { t } = useTranslation("persistedAlert")
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

   return (
     <>
       <Alert severity="warning" onClick={handleOpenModal} sx={{
         "&:hover": {
           cursor: "pointer",
         }
       }}>
         <Typography>{t("alert.label")}</Typography>
       </Alert>
       <Modal handleClose={handleClose} open={openModal}>
          <Box sx={(theme)=>({
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: pxToRem(25),
            width: "100%",
            padding: pxToRem(30),
            borderWidth: pxToRem(4),
            borderColor: theme.palette.inexPrimary.main,
            borderStyle: "solid",
          })}>
            <Box style={{width: "100%", justifyContent: "center", display: "flex", flexDirection: "row"}}>
              <Box flex={1}>
                <InexBusinessLogo />
              </Box>
              <Typography variant="h4" flex={5} textAlign="center">{t("modal.title")}</Typography>
              <Box flex={1}/>
            </Box>
            <Box style={{ justifyContent: "center", display: "flex", flexDirection: "column", gap: pxToRem(10) }}>
              <Typography variant="h6">{t("modal.content.subtitle")}</Typography>
              <Typography variant="subtitle1" fontWeight="bold">{t("modal.content.content1")}</Typography>
              <List sx={{ listStyleType: 'disc', pl: 4  }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    {t("modal.content.content2")}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    {t("modal.content.content3")}
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="subtitle1" fontWeight="bold">{t("modal.content.content4")}</Typography>
              <List sx={{ listStyleType: 'disc', pl: 4  }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    {t("modal.content.content5")}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    {t("modal.content.content6")}
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>
                    {t("modal.content.content7")}
                  </Typography>
                </ListItem>
              </List>
              <Typography>{t("modal.content.content8")}</Typography>
              <Typography>{t("modal.content.content9")}</Typography>
              <Typography>{t("modal.content.content10")}</Typography>
              <Typography>{t("modal.content.content11")}</Typography>
            </Box>
            <Box style={{width: "100%", justifyContent: "flex-end", display: "flex"}}>
              <Button onClick={handleClose}>
                <Typography>{t("modal.button.close.label")}</Typography>
              </Button>
            </Box>
          </Box>
       </Modal>
     </>
   )

}

export default PersistedAlert;
